import React from "react";
import Confirm from "../../Utilities/Components/ConfirmModal";
import { Panel, Table, Well, Button } from "react-bootstrap";
import { INVALID_COLUMN_NUMBER, DATE_FORMATS } from "../../constants";
import { lookupTableView, numberToColumnName } from "../Mapping/InputColumn";
import { REVENUE_TYPE_KEY } from "../../Utilities/Components/LookupTable";

class SalesMappingDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.renderColumn = this.renderColumn.bind(this);
    this.displayColumn = this.displayColumn.bind(this);
    this.displayDate = this.displayDate.bind(this);
    this.displayRevenueType = this.displayRevenueType.bind(this);
    this.displayCurrency = this.displayCurrency.bind(this);
    this.displayTargetCurrency = this.displayTargetCurrency.bind(this);
    this.displayCurrencyDate = this.displayCurrencyDate.bind(this);
    this.displaySalesDeduction = this.displaySalesDeduction.bind(this);
    this.requestDeleteMapping = this.requestDeleteMapping.bind(this);
    this.displayDataHeader = this.displayDataHeader.bind(this);
    this.displaySkipRow = this.displaySkipRow.bind(this);
  }

  renderColumn(labelText, valueText) {
    return (
      <tr>
        <td>
          <b>{labelText}</b>
        </td>
        <td>{valueText}</td>
      </tr>
    );
  }

  displayColumn(name, columnNumber) {
    let text = ""
    if (null === columnNumber || INVALID_COLUMN_NUMBER === columnNumber) {
      text = "Column is not present in the file.";
    } else {
      text = numberToColumnName(columnNumber);
    }
    return this.renderColumn(name, text);
  }

  displayDate() {
    const { assign_date, transaction_date_column } = this.props.mapping;
    let text = "";

    if (assign_date === true) {
      text = "We will ask you at the time of import for the date."
    } else {
      text = numberToColumnName(transaction_date_column);
    }
    return this.renderColumn("Transaction Date", text);
  }

  displayDateFormat(){
    const { assign_date, date_format } = this.props.mapping;
    let text = "";

    if (assign_date === true) {
      text = "N/A";
    } else {
      text = DATE_FORMATS[date_format];
    }
    return this.renderColumn("Date Format", text);
  }

  displayRevenueType() {
    const { mapping, lookupProcessingEnabled } = this.props;

    if (lookupProcessingEnabled && mapping.lookup_columns && mapping.lookup_columns !== []) {
      return lookupTableView(REVENUE_TYPE_KEY, mapping.lookup_columns);
    } else if (mapping.assign_revenue_type_field) {
      return this.renderColumn(
        REVENUE_TYPE_KEY,
        `We will assign the revenue type, '${mapping.revenue_type_field}', to all transactions in this file.`
      );
    } else {
      return this.displayColumn(
        REVENUE_TYPE_KEY,
        mapping.revenue_type_column
      );
    }
  }

  displayAccountName() {
    const label = "Account Name";
    const {
      mapping: {
        assign_account_name_column,
        assign_account_name_field,
        account_name_field,
        account_name_column
      }
    } = this.props;

    if (assign_account_name_column) {
      return this.displayColumn(label, account_name_column);
    } else if (assign_account_name_field) {
      return this.renderColumn(
        label,
        `We will set the ${label} to '${account_name_field}' for all records in this file.`
      );
    }
  }

  displayCurrency() {
    const { currency_conversion, currency_in_file, conversion_rate } = this.props.mapping;

    if (currency_conversion) {
      if (currency_in_file) {
        return this.displayColumn(
          conversion_rate ? "Conversion Rate" : "Currency Conversion",
          this.props.mapping.currency_code_column
        );
      } else {
        return (
          <>
            <tr>
              <td>
                <b>Currency Conversion</b>
              </td>
              <td>
                We will ask what currency the file is in at the time of import.
              </td>
            </tr>
            {conversion_rate && (
              <tr>
                <td>
                  <b>Conversion Rate</b>
                </td>
                <td>
                  We will ask what conversion rate the file is in at the time of
                  import.
                </td>
              </tr>
            )}
          </>
        );
      }
    } else {
      return this.renderColumn("Currency Conversion", "No currency conversion is necessary.");
    }
  }

  displayTargetCurrency() {
    const { currency_conversion, currency_in_file, target_currency_code } = this.props.mapping;

    if (currency_conversion) {
      if (currency_in_file) {
        return this.renderColumn("Target Currency Conversion", target_currency_code);
      }
    }

    return null;
  }

  displayCurrencyDate() {
    const { currency_conversion, specify_currency_date } = this.props.mapping;

    if (!currency_conversion) return;

    let text = "";

    if (specify_currency_date) {
      text = "We will ask what date to use for the currency conversion.";
    } else {
      text = "We use the transaction date for the date of the currency conversion.";
    }
    return this.renderColumn("Currency Conversion Date", text);
  }

  displaySalesDeduction() {
    const { deduction_column, sales_deduction_rate } = this.props.mapping;
    const label = "Sales Deduction";

    if (deduction_column === null) {
      let text = "";

      if (sales_deduction_rate == 0) {
        text = "No sales deduction for this file.";
      } else {
        text = `We will deduct ${sales_deduction_rate}% from every sale.`;
      }
      return this.renderColumn(label, text);
    }

    return this.displayColumn(label, deduction_column);
  }

  displaySkipRow() {
    const { skipRowProcessingEnabled } = this.props;
    const { skip_match_column, skip_match_expression, use_skip_row_processor } = this.props.mapping;

    // Hide this feature if it's disable AND it's not already in use for this mapping
    if (!skipRowProcessingEnabled && !use_skip_row_processor) {
      return null;
    }

    const label = "Skip Rows";
    let text = "Not in use";

    if (use_skip_row_processor) {
      text = (<>Match value in column #{skip_match_column + 1} against text:<br/>{skip_match_expression}</>);
    }

    return this.renderColumn(label, text);
  }

  displaySelectedSheet() {
    const { sheet_number } = this.props.mapping;
    const num = (sheet_number === null ? 0 : sheet_number) + 1;

    return this.renderColumn("Sheet", `Sheet #${num}`);
  }

  displayDataHeader() {
    return this.renderColumn(
      "Header Row",
      `Header Row is row number ${this.props.mapping.header_row + 1}`
    );
  }

  requestDeleteMapping() {
    return Confirm("Are you sure you want to delete this template?", {
      yesStyle: "danger",
      header: "Confirm Deletion",
    }).then(() => {
      this.props.deleteMapping();
    });
  }

  render() {
    const { mapping } = this.props;

    return (
      <Panel header={<div>{mapping.name}</div>}>
        <Well className="mappingNotes">
          <p>
            <b>Notes</b>
          </p>
          {mapping.notes}
        </Well>
        <Table bordered condensed>
          <tbody>
            {this.displaySelectedSheet()}
            {this.displayDataHeader()}
            {this.displaySkipRow()}
            {this.displayColumn("Product ID", mapping.product_id_column)}
            {this.displayDate()}
            {this.displayDateFormat()}
            {this.displayColumn(
              "Gross Units Sold",
              mapping.gross_units_sold_column
            )}
            {this.displayColumn("Units Returned", mapping.units_return_column)}
            {this.displayColumn(
              "Net Units Sold",
              mapping.net_units_sold_column
            )}
            {this.displayColumn("List Price", mapping.list_price_column)}
            {this.displayColumn("Net Amount", mapping.net_amount_column)}
            {this.displayColumn("Discount", mapping.discount_column)}
            {this.displayColumn("Discount Rate", mapping.discount_rate_column)}
            {this.displayRevenueType()}
            {this.displayColumn("Mfg Cost", mapping.mfg_cost_column)}
            {this.displayColumn("Tax", mapping.tax_column)}
            {this.displayColumn("Shipping", mapping.shipping_column)}
            {this.displayCurrency()}
            {this.displayTargetCurrency()}
            {this.displayCurrencyDate()}
            {this.displaySalesDeduction()}
            {this.displayColumn("Payout Plan", mapping.payout_plan_column)}
            {this.displayColumn("Buyer", mapping.buyer_column)}
            {this.displayColumn("Territory", mapping.territory_column)}
            {this.displayAccountName()}
            {this.displayColumn(
              "Invoice Number",
              mapping.invoice_number_column
            )}
            {this.displayColumn("Format", mapping.format_column)}
            {this.displayColumn("Author Name", mapping.author_name_column)}
            {this.displayColumn("Project Name", mapping.project_name_column)}
            {this.displayColumn("Misc Alpha 1", mapping.misc_alpha_1_column)}
            {this.displayColumn("Misc Alpha 2", mapping.misc_alpha_2_column)}
            {this.displayColumn("Misc Alpha 3", mapping.misc_alpha_3_column)}
            {this.displayColumn("Misc Number 1", mapping.misc_number_1_column)}
            {this.displayColumn("Misc Number 2", mapping.misc_number_2_column)}
          </tbody>
        </Table>

        <div className="pull-right">
          <Button
            key="editMapping"
            bsStyle="primary"
            onClick={this.props.editMapping}
            disabled={mapping.archived === true}
            className="util-margin-right"
          >
            Edit
          </Button>
          <Button
            key="markAsArchived"
            onClick={this.props.markAsArchived}
            disabled={mapping.archived === true}
            className="util-margin-right"
          >
            Archive Template
          </Button>
          <Button
            key="deleteMapping"
            bsStyle="danger"
            disabled={mapping.used}
            onClick={this.requestDeleteMapping}
          >
            Delete Template
          </Button>
        </div>
      </Panel>
    );
  }
}

SalesMappingDisplay.defaultProps = {};

export default SalesMappingDisplay;
