import $ from "jquery";
import React, { Component } from 'react'
import { Col, Row, Alert, Panel, ListGroup, ListGroupItem, Button} from "react-bootstrap";
import { v4 as uuid } from "uuid";
import classNames from "classnames"
import XLSX from 'xlsx';
import SalesFileDisplay from "./SalesFileDisplay";
import SalesMappingStart from "./Steps/SalesMappingStart";
import SalesMappingStep1 from "./Steps/SalesMappingStep1";
import SalesMappingStep2a from "./Steps/SalesMappingStep2a";
import SalesMappingStep2b from "./Steps/SalesMappingStep2b";
import SalesMappingStep3 from "./Steps/SalesMappingStep3";
import SalesMappingStep8 from "./Steps/SalesMappingStep8";
import SalesMappingStep11 from "./Steps/SalesMappingStep11";
import SalesMappingStep12 from "./Steps/SalesMappingStep12";
import SalesMappingStep13 from "./Steps/SalesMappingStep13";
import SalesMappingStepAccountName from "./Steps/SalesMappingStepAccountName";
import SalesMappingStepAssignName from "./Steps/SalesMappingStepAssignName";
import SalesMappingStep from "./Steps/SalesMappingStep";
import SalesMappingSkipRow from "./Steps/SalesMappingSkipRow";
import SalesMappingSummary from "./Steps/SalesMappingSummary";
import SalesMappingDisplay from "../ImportSales/SalesMappingDisplay";
import {INVALID_COLUMN_NUMBER, VALUE_NONE} from "../../constants"

class SalesMappingView extends Component {
  static SKIP_ROW_STEP = 33;
  static ASSIGN_NAME_STEP = 34;

  constructor(props) {
    super(props);

    this.state = {
      alert: "",
      alertVisible: false,
      alertSuccessful: false,
      page: 0,
      productIDColumn: INVALID_COLUMN_NUMBER,
      assignDate: null,
      transactionDateColumn: INVALID_COLUMN_NUMBER,
      dateFormat: "%m/%d/%Y",
      netUnitsSoldColumn: INVALID_COLUMN_NUMBER,
      netUnitsSoldField: "",
      listPriceColumn: INVALID_COLUMN_NUMBER,
      netAmountColumn: INVALID_COLUMN_NUMBER,
      discountColumn: INVALID_COLUMN_NUMBER,
      discountRateColumn: INVALID_COLUMN_NUMBER,
      revenueTypeColumn: INVALID_COLUMN_NUMBER,
      lookupColumns: [],
      revenueTypeField: "",
      mfgCostColumn: INVALID_COLUMN_NUMBER,
      conversionRate: null,
      rateConversion: null,
      taxColumn: INVALID_COLUMN_NUMBER,
      shippingColumn: INVALID_COLUMN_NUMBER,
      currencyInFile: null,
      currencyCodeColumn: INVALID_COLUMN_NUMBER,
      targetCurrencyCode: null,
      specifyCurrencyDate: null,
      deductionType: null,
      deductionColumn: INVALID_COLUMN_NUMBER,
      payoutPlanColumn: INVALID_COLUMN_NUMBER,
      negativeDeductionSign: null,
      salesDeductionRate: null,
      buyerColumn: INVALID_COLUMN_NUMBER,
      territoryColumn: INVALID_COLUMN_NUMBER,
      accountNameColumn: INVALID_COLUMN_NUMBER,
      accountNameField: "",
      invoiceNumberColumn: INVALID_COLUMN_NUMBER,
      authorNameColumn: INVALID_COLUMN_NUMBER,
      projectNameColumn: INVALID_COLUMN_NUMBER,
      formatColumn: INVALID_COLUMN_NUMBER,
      grossUnitsSoldColumn: INVALID_COLUMN_NUMBER,
      unitsReturnColumn: INVALID_COLUMN_NUMBER,
      misc1Column: INVALID_COLUMN_NUMBER,
      misc2Column: INVALID_COLUMN_NUMBER,
      misc3Column: INVALID_COLUMN_NUMBER,
      misc4Column: INVALID_COLUMN_NUMBER,
      misc5Column: INVALID_COLUMN_NUMBER,
      useSkipRowProcessor: false,
      skipMatchColumn: INVALID_COLUMN_NUMBER,
      skipMatchExpression: null,
      assignRevenueTypeField: false,
      assignRevenueTypeColumn: false,
      assignRevenueTypeColumnUsingLookupTable: false,
      assignNetUnitsSoldField: false,
      assignNetUnitsSoldColumn: false,
      assignAccountNameField: false,
      assignAccountNameColumn: false,
      editingMapping: false,
      headerRow: 0,
      name: "",
      notes: "",
      highlightColumn: null,
      highlightRow: null,
      excelWorkbook: null,
      sheetNumber: 0,
      sheetCount: 1,
      fileName: null,
      fileType: '',
      fileData: [
        ["Product ID", "Date", "Units Sold", "List Price", "Actual Amount", "Revenue Type", "Discount"],
        ["9876543210123", "9/30/2018", 10, 14.99, 149.90, "EB", 0.00],
        ["9876543210124", "9/30/2018", 11, 14.99, 164.89, "EB", 0.00],
        ["9876543210125", "9/30/2018", 12, 14.99, 179.88, "EB", 0.00],
        ["9876543210126", "9/30/2018", 13, 14.99, 194.87, "EB", 0.00],
        ["9876543210127", "9/30/2018", 14, 14.99, 209.86, "EB", 0.00]
      ],
      filePreviewData: [],
      fileUploaded: false,
      mappings: [],
      revenueTypes: [],
      activeMapping: null,
      showArchived: false,
    }

    this.setPage0 = this.setPage0.bind(this);
    this.setPage1 = this.setPage1.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.shouldSkipPage = this.shouldSkipPage.bind(this);
    this.assignNamePage = this.assignNamePage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPageSheetSelection = this.nextPageSheetSelection.bind(this);
    this.nextPageSelectHeader = this.nextPageSelectHeader.bind(this);
    this.previousPageSheetSelection = this.previousPageSheetSelection.bind(this);
    this.setColumn = this.setColumn.bind(this);
    this.setHighlightColumn = this.setHighlightColumn.bind(this);
    this.parseData = this.parseData.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.setAssignDate = this.setAssignDate.bind(this);
    this.setDateFormat = this.setDateFormat.bind(this);
    this.setLookUpRevenueTypeID = this.setLookUpRevenueTypeID.bind(this);
    this.setRevenueTypeColumn = this.setRevenueTypeColumn.bind(this);
    this.setLookupColumns = this.setLookupColumns.bind(this);
    this.setRevenueTypeField = this.setRevenueTypeField.bind(this);
    this.setNetUnitsSoldColumn = this.setNetUnitsSoldColumn.bind(this);
    this.setNetUnitsSoldField = this.setNetUnitsSoldField.bind(this);
    this.setAssignRevenueTypeColumn = this.setAssignRevenueTypeColumn.bind(this);
    this.setAssignRevenueTypeColumnUsingLookupTable = this.setAssignRevenueTypeColumnUsingLookupTable.bind(this);
    this.setAssignRevenueTypeField = this.setAssignRevenueTypeField.bind(this);
    this.setAssignNetUnitsSoldColumn = this.setAssignNetUnitsSoldColumn.bind(this);
    this.setAssignNetUnitsSoldField = this.setAssignNetUnitsSoldField.bind(this);
    this.skipRevenueType = this.skipRevenueType.bind(this);
    this.skipNetUnitsSold = this.skipNetUnitsSold.bind(this);
    this.setAccountNameColumn = this.setAccountNameColumn.bind(this);
    this.setAccountNameField = this.setAccountNameField.bind(this);
    this.setAssignAccountNameColumn = this.setAssignAccountNameColumn.bind(this);
    this.setAssignAccountNameField = this.setAssignAccountNameField.bind(this);
    this.skipAccountName = this.skipAccountName.bind(this);
    this.setCurrencyConversion = this.setCurrencyConversion.bind(this);
    this.setTargetCurrencyCode = this.setTargetCurrencyCode.bind(this);
    this.setConversionRate = this.setConversionRate.bind(this);
    this.setCurrencyInFile = this.setCurrencyInFile.bind(this);
    this.setSpecifyCurrencyDate = this.setSpecifyCurrencyDate.bind(this);
    this.setSalesDeductionRate = this.setSalesDeductionRate.bind(this);
    this.setDeductionType = this.setDeductionType.bind(this);
    this.setNotes = this.setNotes.bind(this);
    this.setName = this.setName.bind(this);
    this.setHeaderRow = this.setHeaderRow.bind(this);
    this.createMapping = this.createMapping.bind(this);
    this.updateMapping = this.updateMapping.bind(this);
    this.salesFileMapping = this.salesFileMapping.bind(this);
    this.alertWindow = this.alertWindow.bind(this);
    this.preventUsingScientificNotation = this.preventUsingScientificNotation.bind(this);
    this.sheet2arr = this.sheet2arr.bind(this);
    this.setCsvData = this.setCsvData.bind(this);
    this.setExcelData = this.setExcelData.bind(this);
    this.setSheetNumber = this.setSheetNumber.bind(this);
    this.showWholeFile = this.showWholeFile.bind(this);
    this.mappingItem = this.mappingItem.bind(this);
    this.activateMappingHandler = this.activateMappingHandler.bind(this);
    this.findMapping = this.findMapping.bind(this);
    this.displayTemplate = this.displayTemplate.bind(this);
    this.markAsArchived = this.markAsArchived.bind(this);
    this.requeryMappings = this.requeryMappings.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
    this.deleteMapping = this.deleteMapping.bind(this);
    this.noneToNull = this.noneToNull.bind(this);
    this.editMapping = this.editMapping.bind(this);
    this.nextDisabled = this.nextDisabled.bind(this);
    this.setUseSkipRowProcessor = this.setUseSkipRowProcessor.bind(this);
    this.setSkipMatchColumn = this.setSkipMatchColumn.bind(this);
    this.setSkipMatchExpression = this.setSkipMatchExpression.bind(this);
  }

  alertWindow() {
    const { alert, alertSuccessful } = this.state;
    const classes = classNames({
      "danger": !alertSuccessful,
      "success": alertSuccessful
    });

    return (
      <Alert bsStyle={classes} onDismiss={this.handleAlertDismiss}>
        {alert}
      </Alert>
    );
  }

  requeryMappings(activeUUID){
    $.ajax({
      url: "/mappings",
      type: "get",
      statusCode: {
        200: (response) => {
          this.setState({
            mappings: response
          });
          let mapping = null;

          if (activeUUID) {
            mapping = this.findMapping(activeUUID) || null;
          }
          this.setState({
            activeMapping: mapping
          });
        }
      }
    });
  }

  componentDidMount() {
    this.requeryMappings();
  }

  createMapping() {
    const handleNotCreated = () => {
      this.setState({
        alert: "Template was not created.",
        alertVisible: true,
        alertSuccessful: false,
      });
    }

    $.ajax({
      url: "/mappings",
      type: "POST",
      data: this.salesFileMapping(),
      statusCode: {
        201: (response) => {
          this.setState({
            alert: "Template was created successfully.",
            alertVisible: true,
            alertSuccessful: true,
          });
          this.setPage0();
          this.requeryMappings();
        },
        422: handleNotCreated,
        500: handleNotCreated,
        503: () => {
          this.setState({
            alert: "Template is taking a longer time to create.",
            alertVisible: true,
            alertSuccessful: true,
          });
        }
      }
    });
  }

  updateMapping() {
    const handleNotUpdated = () => {
      this.setState({
        alert: "Template was not updated.",
        alertVisible: true,
        alertSuccessful: false,
      });
    }

    $.ajax({
      url: "/mappings/" + this.state.activeMapping.uuid,
      type: "PUT",
      data: this.salesFileMapping(),
      statusCode: {
        200: (response) => {
          this.setState({
            alert: "Template was updated successfully.",
            alertVisible: true,
            alertSuccessful: true,
          });
          this.setPage0();
          this.requeryMappings(this.state.activeMapping.uuid);
        },
        422: handleNotUpdated,
        500: handleNotUpdated,
        503: () => {
          this.setState({
            alert: "Template is taking a longer time to update.",
            alertVisible: true,
            alertSuccessful: true,
          });
        }
      }
    });
  }

  showWholeFile(){
    return this.state.page === 3;
  }

  noneToNull(value) {
    return VALUE_NONE === value ? null : value;
  }

  nextDisabled(value) {
    return null === value || INVALID_COLUMN_NUMBER === value;
  }

  salesFileMapping() {
    return (
      {
        mapping: {
          account_name_column: this.noneToNull(this.state.accountNameColumn),
          account_name_field: this.noneToNull(this.state.accountNameField),
          assign_account_name_field: this.state.assignAccountNameField,
          assign_account_name_column: this.state.assignAccountNameColumn,
          assign_date: this.state.assignDate,
          assign_net_units_sold_column: this.state.assignNetUnitsSoldColumn,
          assign_net_units_sold_field: this.state.assignNetUnitsSoldField,
          assign_revenue_type_column: this.state.assignRevenueTypeColumn,
          assign_revenue_type_column_using_lookup_table: this.state.assignRevenueTypeColumnUsingLookupTable,
          assign_revenue_type_field: this.state.assignRevenueTypeField,
          author_name_column: this.noneToNull(this.state.authorNameColumn),
          buyer_column: this.noneToNull(this.state.buyerColumn),
          conversion_rate: this.state.conversionRate,
          currency_code_column: this.state.currencyCodeColumn,
          target_currency_code: this.state.targetCurrencyCode,
          tax_column: this.noneToNull(this.state.taxColumn),
          shipping_column: this.noneToNull(this.state.shippingColumn),
          currency_conversion: this.state.currencyConversion,
          currency_in_file: this.state.currencyInFile,
          deduction_column: this.noneToNull(this.state.deductionColumn),
          deduction_type: this.noneToNull(this.state.deductionType),
          discount_column: this.noneToNull(this.state.discountColumn),
          discount_rate_column: this.noneToNull(this.state.discountRateColumn),
          format_column: this.noneToNull(this.state.formatColumn),
          gross_units_sold_column: this.noneToNull(this.state.grossUnitsSoldColumn),
          header_row: this.state.headerRow,
          invoice_number_column: this.noneToNull(this.state.invoiceNumberColumn),
          list_price_column: this.noneToNull(this.state.listPriceColumn),
          mfg_cost_column: this.noneToNull(this.state.mfgCostColumn),
          misc_alpha_1_column: this.noneToNull(this.state.miscAlpha1Column),
          misc_alpha_2_column: this.noneToNull(this.state.miscAlpha2Column),
          misc_alpha_3_column: this.noneToNull(this.state.miscAlpha3Column),
          misc_number_1_column: this.noneToNull(this.state.miscNumber1Column),
          misc_number_2_column: this.noneToNull(this.state.miscNumber2Column),
          name: this.state.name,
          negative_deduction_sign: this.state.negativeDeductionSign,
          net_amount_column: this.noneToNull(this.state.netAmountColumn),
          net_units_sold_column: this.noneToNull(this.state.netUnitsSoldColumn),
          net_units_sold_field: this.noneToNull(this.state.netUnitsSoldField),
          notes: this.state.notes,
          payout_plan_column: this.noneToNull(this.state.payoutPlanColumn),
          product_id_column: this.state.productIDColumn,
          project_name_column: this.noneToNull(this.state.projectNameColumn),
          revenue_type_column: this.noneToNull(this.state.revenueTypeColumn),
          lookup_columns: this.state.lookupColumns,
          revenue_type_field: this.noneToNull(this.state.revenueTypeField),
          sales_deduction_rate: this.state.salesDeductionRate,
          sheet_number: this.state.sheetNumber,
          specify_currency_date: this.state.specifyCurrencyDate,
          territory_column: this.noneToNull(this.state.territoryColumn),
          transaction_date_column: this.state.transactionDateColumn,
          date_format: this.state.dateFormat,
          use_skip_row_processor: this.state.useSkipRowProcessor,
          skip_match_column: this.noneToNull(this.state.skipMatchColumn),
          skip_match_expression: this.state.skipMatchExpression,
          units_return_column: this.noneToNull(this.state.unitsReturnColumn),
          uuid: uuid.v4()
        }
      }
    )
  }

  setHeaderRow(selectedRow){
    const headerRow = selectedRow - 1;
    const preview = this.state.fileData.slice(headerRow, headerRow + 20);

    this.setState({
      headerRow: headerRow,
      highlightRow: headerRow,
      filePreviewData: preview
    });
  }

  setPage0() {
    this.setState({
      page: 0,
    })
  }

  setPage1() {
    this.setState({
      page: 1,
      highlightColumn: null,
      highlightRow: null
    })
  }

  shouldSkipPage(page) {
    return (SalesMappingView.SKIP_ROW_STEP == page) && !this.props.skipRowProcessingEnabled;
  }

  nextPage() {
    let next = this.state.page + 1;

    if (this.shouldSkipPage(next)) {
      next = next + 1;
    }

    this.setState({
      page: next,
      highlightRow: null
    })
  }

  assignNamePage() {
    this.setState({
      page: SalesMappingView.ASSIGN_NAME_STEP,
      highlightRow: null
    })
  }

  previousPage() {
    let previous = this.state.page - 1;

    if (this.shouldSkipPage(previous)) {
      previous = previous - 1;
    }

    if (3 === previous) {
      this.setState({
        page: previous,
        highlightColumn: null,
        highlightRow: this.state.headerRow
      });
    } else {
      this.setState({
        page: previous,
        highlightRow: null
      });
    }
  }

  editMapping() {
    const { activeMapping } = this.state;
    const assignRevenueTypeColumnUsingLookupTable = this.props.lookupProcessingEnabled &&
                                                    activeMapping.lookup_columns &&
                                                    activeMapping.lookup_columns.length > 0;

    this.setState({
      page: this.state.page + 1,
      sheetNumber: activeMapping.sheet_number,
      highlightColumn: null,
      highlightRow: null,
      productIDColumn: activeMapping.product_id_column,
      assignDate: activeMapping.assign_date,
      assignRevenueTypeColumn: activeMapping.assign_revenue_type_column,
      assignRevenueTypeColumnUsingLookupTable: assignRevenueTypeColumnUsingLookupTable,
      assignRevenueTypeField: activeMapping.assign_revenue_type_field,
      transactionDateColumn: activeMapping.transaction_date_column,
      dateFormat: activeMapping.date_format,
      assignNetUnitsSoldColumn: activeMapping.assign_net_units_sold_column,
      assignNetUnitsSoldField: activeMapping.assign_net_units_sold_field,
      netUnitsSoldColumn: activeMapping.net_units_sold_column,
      netUnitsSoldField: activeMapping.net_units_sold_field,
      listPriceColumn: activeMapping.list_price_column,
      netAmountColumn: activeMapping.net_amount_column,
      discountColumn: activeMapping.discount_column,
      discountRateColumn: activeMapping.discount_rate_column,
      revenueTypeColumn: activeMapping.revenue_type_column,
      lookupColumns: activeMapping.lookup_columns,
      revenueTypeField: activeMapping.revenue_type_field,
      mfgCostColumn: activeMapping.mfg_cost_column,
      taxColumn: activeMapping.tax_column,
      shippingColumn: activeMapping.shipping_column,
      currencyConversion: activeMapping.currency_conversion,
      conversionRate: activeMapping.conversion_rate,
      currencyInFile: activeMapping.currency_in_file,
      currencyCodeColumn: activeMapping.currency_code_column,
      targetCurrencyCode: activeMapping.target_currency_code,
      specifyCurrencyDate: activeMapping.specify_currency_date,
      deductionColumn: activeMapping.deduction_column,
      deductionType: activeMapping.deduction_type,
      payoutPlanColumn: activeMapping.payout_plan_column,
      negativeDeductionSign: activeMapping.negative_deduction_sign,
      salesDeductionRate: activeMapping.sales_deduction_rate,
      buyerColumn: activeMapping.buyer_column,
      territoryColumn: activeMapping.territory_column,
      accountNameColumn: activeMapping.account_name_column,
      accountNameField: activeMapping.account_name_field,
      assignAccountNameField: activeMapping.assign_account_name_field,
      assignAccountNameColumn: activeMapping.assign_account_name_column,
      invoiceNumberColumn: activeMapping.invoice_number_column,
      authorNameColumn: activeMapping.author_name_column,
      projectNameColumn: activeMapping.project_name_column,
      formatColumn: activeMapping.format_column,
      grossUnitsSoldColumn: activeMapping.gross_units_sold_column,
      unitsReturnColumn: activeMapping.units_return_column,
      miscAlpha1Column: activeMapping.misc_alpha_1_column,
      miscAlpha2Column: activeMapping.misc_alpha_2_column,
      miscAlpha3Column: activeMapping.misc_alpha_3_column,
      miscNumber1Column: activeMapping.misc_number_1_column,
      miscNumber2Column: activeMapping.misc_number_2_column,
      headerRow: activeMapping.header_row,
      useSkipRowProcessor: activeMapping.use_skip_row_processor,
      skipMatchColumn: activeMapping.skip_match_column,
      skipMatchExpression: activeMapping.skip_match_expression,
      name: activeMapping.name,
      notes: activeMapping.notes,
      uuid: activeMapping.uuid,
      editingMapping: true,
    })
  }

  nextPageSelectHeader(){
    let header_row;
    const { activeMapping } = this.state;

    if (activeMapping != null) {
      header_row = activeMapping.header_row;
    }

    this.setState({
      page: 3,
      highlightColumn: null,
      highlightRow: header_row
    });
  }

  nextPageSheetSelection() {
    const promptForSheetNumber = this.state.sheetCount > 1;
    const nextPage = promptForSheetNumber ? this.state.page + 1 : this.state.page + 2;

    if (nextPage === 3){
      this.setState({
        page: nextPage,
        highlightColumn: null,
        highlightRow: 0
      });
    } else {
      this.setState({
        page: nextPage,
        highlightColumn: null,
        highlightRow: null
      });
    }

  }

  previousPageSheetSelection() {
    const promptForSheetNumber = this.state.sheetCount > 1;
    const nextPage = promptForSheetNumber ? this.state.page - 1 : this.state.page - 2;

    this.setState({
      page: nextPage,
      highlightColumn: null,
    });
  }

  setColumn(field, value){
    this.setState({
      [`${field}`]: value,
      highlightColumn: value
    })
  }

  setHighlightColumn(value){
    this.setState({
      highlightColumn: value
    })
  }

  setDateFormat(value){
    this.setState({
      dateFormat: value,
    })
  }

  setSheetNumber(event) {
    const sheetNumber = parseInt(event.target.value);
    const sheet = this.state.excelWorkbook.Sheets[this.state.excelWorkbook.SheetNames[sheetNumber]];
    const parsedSheet = this.sheet2arr(sheet);

    this.setState({
      sheetNumber: sheetNumber,
      fileData: parsedSheet,
      filePreviewData: parsedSheet.slice(0, 20)
    });
  }

  setExcelData(excelWorkbook, parsedSheet, fileType){
    this.setState({
          excelWorkbook,
          sheetCount: excelWorkbook.SheetNames.length,
          fileData: parsedSheet,
          filePreviewData: parsedSheet.slice(0, 6),
          fileType: fileType,
          fileUploaded: true
    });
  }

  setCsvData(parsedSheet, fileType){
    this.setState({
      sheetCount: 1,
      fileData: parsedSheet,
      filePreviewData: parsedSheet.slice(0, 6),
      fileType: fileType,
      fileUploaded: true,
    });
  }

  setAssignDate(assignDate){
    this.setState({
      assignDate: assignDate,
      transactionDateColumn: assignDate ? null : INVALID_COLUMN_NUMBER,
      highlightColumn: null
    })
  }

  setLookUpRevenueTypeID(value){
    this.setState({
      lookUpRevenueTypeID: value,
      highlightColumn: null
    })
  }

  setRevenueTypeColumn(value){
    this.setState({
      revenueTypeColumn: value,
      highlightColumn: value
    })
  }

  setLookupColumns(value){
    const { lookupProcessingEnabled } = this.props;
    const lookupColumns = lookupProcessingEnabled && value ? value : [];
    const highlightColumn = lookupProcessingEnabled && lookupColumns.length > 0 ?
                            lookupColumns[0].lookup_key_columns.map(item => parseInt(item, 10)) :
                            null;

    this.setState({
      lookupColumns: lookupColumns,
      highlightColumn: highlightColumn,
    })
  }

  setRevenueTypeField(value){
    this.setState({
      revenueTypeField: value,
      highlightColumn: null
    })
  }

  setAssignRevenueTypeColumn(){
    this.setState({
      assignRevenueTypeColumn: true,
      assignRevenueTypeColumnUsingLookupTable: false,
      assignRevenueTypeField: false,
      highlightColumn: null,
      // revenueTypeColumn: null, // preserving this value
      lookupColumns: [],
      lookupTable: null
    })
  }

  setAssignRevenueTypeColumnUsingLookupTable() {
    this.setState({
      assignRevenueTypeColumn: false,
      assignRevenueTypeColumnUsingLookupTable: true,
      assignRevenueTypeField: false,
      highlightColumn: null,
      revenueTypeColumn: null,
      // lookupColumns: [], // preserving this value
      // lookupTable: null // preserving this value
    })
  }

  setAssignRevenueTypeField(){
    this.setState({
      assignRevenueTypeColumn: false,
      assignRevenueTypeColumnUsingLookupTable: false,
      assignRevenueTypeField: true,
      highlightColumn: null,
      revenueTypeColumn: null,
      lookupColumns: [],
      lookupTable: null
    })
  }

  setNetUnitsSoldColumn(value){
    this.setState({
      netUnitsSoldColumn: value,
      highlightColumn: value
    })
  }

  setNetUnitsSoldField(value){
    this.setState({
      netUnitsSoldField: value,
      highlightColumn: null
    })
  }

  setAssignNetUnitsSoldColumn(){
    this.setState({
      assignNetUnitsSoldColumn: true,
      assignNetUnitsSoldField: false,
      net_units_sold_field: null
    })
  }

  setAssignNetUnitsSoldField(){
    this.setState({
      assignNetUnitsSoldColumn: false,
      assignNetUnitsSoldField: true,
      net_units_sold_column: null
    })
  }

  skipRevenueType(){
    this.setState({
      assignRevenueTypeColumn: false,
      assignRevenueTypeColumnUsingLookupTable: false,
      assignRevenueTypeField: false,
      revenueTypeColumn: null,
      lookupColumns: [],
      lookupTable: null,
      highlightColumn: null
    })
  }

  skipNetUnitsSold(){
    this.setState({
      netUnitsSoldColumn: null,
      netUnitsSoldField: 0,
      assignNetUnitsSoldColumn: false,
      assignNetUnitsSoldField: true
    })
  }

  setAccountNameColumn(value){
    this.setState({
      accountNameColumn: value,
      highlightColumn: value
    })
  }

  setAccountNameField(value){
    this.setState({
      accountNameField: value,
      highlightColumn: null
    })
  }

  setAssignAccountNameColumn(){
    this.setState({
      assignAccountNameColumn: true,
      assignAccountNameField: false
    })
  }

  setAssignAccountNameField(){
    this.setState({
      assignAccountNameColumn: false,
      accountNameColumn: null,
      assignAccountNameField: true
    })
  }

  skipAccountName(){
    this.setState({
      accountNameColumn: null,
      accountNameField: null,
      assignAccountNameColumn: false,
      assignAccountNameField: false,
    })
  }

  setCurrencyConversion(currencyConversion){
    this.setState({
      currencyConversion: currencyConversion,
      conversionRate: null,
      currencyInFile: null,
      currencyCodeColumn: currencyConversion ? INVALID_COLUMN_NUMBER : null,
      targetCurrencyCode: null,
      specifyCurrencyDate: null,
    })
  }

  setTargetCurrencyCode(targetCurrencyCode){
    this.setState({
      targetCurrencyCode: targetCurrencyCode,
    })
  }

  setConversionRate(conversionRate){
    this.setState({
      conversionRate: conversionRate,
      currencyCodeColumn: null,
      targetCurrencyCode: null,
      specifyCurrencyDate: null,
    })
  }

  setSpecifyCurrencyDate(value){
    this.setState({
      specifyCurrencyDate: value
    })
  }

  setCurrencyInFile(value){
    this.setState({
      currencyInFile: value,
      currencyCodeColumn: INVALID_COLUMN_NUMBER,
      targetCurrencyCode: null,
      conversionRate: null,
      specifyCurrencyDate: null,
    })

    if (!value) {
      this.setColumn(
        "currencyCodeColumn",
        null
      );
    }
  }

  setSalesDeductionRate(value){
    this.setState({
      salesDeductionRate: value
    })
  }

  setDeductionType(value){
    this.setState({
      deductionType: value
    })
  }

  setNotes(text){
    this.setState({
      notes: text
    })
  }

  setName(text){
    this.setState({
      name: text
    })
  }

  setUseSkipRowProcessor(value){
    this.setState({
      useSkipRowProcessor: value
    })
  }

  setSkipMatchColumn(value){
    this.setState({
      skipMatchColumn: value,
      highlightColumn: value
    })
  }

  setSkipMatchExpression(value){
    this.setState({
      skipMatchExpression: value
    })
  }

  preventUsingScientificNotation(cell){
    if(cell.t === 'n' && cell.w.indexOf('E') !== -1){
      delete cell.w;
      cell.z = '0';
    }
  }

  sheet2arr(sheet) {
    let rows = [];
    const sheetRef = sheet['!ref'];

    if (sheetRef) {
      let row, rowNum, colNum;
      const data = XLSX.utils.decode_range(sheetRef);

      for(rowNum = data.s.r; rowNum <= data.e.r; rowNum++) {
        row = [];
        for(colNum=data.s.c; colNum<=data.e.c; colNum++) {
          const nextCell = sheet[XLSX.utils.encode_cell({r: rowNum, c: colNum})];
          if(typeof nextCell === 'undefined' ) {
              row.push(void 0)
          }
          else {
            this.preventUsingScientificNotation(nextCell);
            row.push(XLSX.utils.format_cell(nextCell));
          }
        }

        rows.push(row);
      }
    }

    return rows;
  }

  parseData(e) {
    const reader = new FileReader();
    const setCsvData = this.setCsvData;
    const setExcelData = this.setExcelData;
    const sheet2arr = this.sheet2arr;

    const files = e.target.files, f = files[0];

    reader.onload = function(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, {type: 'binary', dateNF: 'mm/dd/yyyy', raw: true, sheetRows: 50});
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const output = sheet2arr(sheet);

      if(f.type === 'text/csv' || f.name.split('.').pop() === 'csv') {
          setCsvData(output, 'text/csv');
      } else {
          setExcelData(workbook, output, f.type);
      }
    };

    this.setState({fileName: f.name});

    reader.readAsBinaryString(f);
  }

  renderStep(){
    const { headerRow, activeMapping } = this.state;
    let id;

    if (activeMapping != null){
      id = activeMapping.id;
    }

    switch(this.state.page){
      case 0:
        return <SalesMappingStart
          nextPage={ this.nextPage }
        />;
      case 1:
        return <SalesMappingStep1
          highlightColumn={this.state.highlightColumn}
          nextPage={ this.nextPageSheetSelection }
          fileUploaded={ this.state.fileUploaded }
          fileName={ this.state.fileName }
          parseData={ this.parseData } />;
      case 2:
        return <SalesMappingStep2a
          highlightColumn={this.state.highlightColumn}
          excelWorkbook={this.state.excelWorkbook}
          sheetNumber={this.state.sheetNumber}
          setSheetNumber={this.setSheetNumber}
          nextPage={ this.nextPageSelectHeader }
          previousPage={ this.previousPage }
          parseData={ this.parseData } />;
      case 3:
        return <SalesMappingStep2b
          highlightColumn={this.state.highlightColumn}
          nextPage={ this.nextPage }
          previousPage={ this.previousPageSheetSelection }
          setHeaderRow={this.setHeaderRow}
          headerRow={this.state.headerRow}
          parseData={ this.parseData } />;
      case 4:
        return <SalesMappingStep
          key={`productIDColumn${id}`}
          panelTitle={"Product Identifier"}
          description={"Which column contains the product identifier?"}
          columnName={"productIDColumn"}
          disableNext={this.nextDisabled(this.state.productIDColumn)}
          addNoneSelection={false}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          mappingColumn={String(this.state.productIDColumn)}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 5:
        return <SalesMappingStep3
          columnNames={ this.state.fileData[headerRow] }
          highlightColumn={this.state.highlightColumn}
          transactionDateColumn={this.state.transactionDateColumn}
          assignDate={this.state.assignDate}
          setAssignDate={this.setAssignDate}
          setDateFormat={this.setDateFormat}
          dateFormat={this.state.dateFormat}
          setColumn={ this.setColumn }
          setHighlightColumn={this.setHighlightColumn}
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 6:
        return <SalesMappingStep
          key={`grossUnitsSoldColumn${id}`}
          panelTitle={"Gross Units Sold"}
          description={"Which column contains the number of units sold? This amount does not include any return units. Click the Skip button if that does not apply to this file."}
          columnName={"grossUnitsSoldColumn"}
          mappingColumn={String(this.state.grossUnitsSoldColumn)}
          disableNext={this.nextDisabled(this.state.grossUnitsSoldColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 7:
        return <SalesMappingStep
          key={`unitsReturnColumn${id}`}
          panelTitle={"Units Returned"}
          description={"Which column contains the number of units returned? Click the Skip button if that does not apply to this file."}
          columnName={"unitsReturnColumn"}
          mappingColumn={String(this.state.unitsReturnColumn)}
          disableNext={this.nextDisabled(this.state.unitsReturnColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 8:
        return <SalesMappingStep8
          assignNamePage={ this.assignNamePage }
          netUnitsColumn={this.state.netUnitsSoldColumn}
          setHighlightColumn={this.setHighlightColumn}
          netUnitsField={this.state.netUnitsSoldField}
          assignNetUnitsSoldColumn={ this.state.assignNetUnitsSoldColumn }
          assignNetUnitsSoldField={ this.state.assignNetUnitsSoldField }
          setNetUnitsSoldColumn={ this.setNetUnitsSoldColumn }
          setNetUnitsSoldField={ this.setNetUnitsSoldField }
          setAssignNetUnitsSoldColumn={ this.setAssignNetUnitsSoldColumn }
          setAssignNetUnitsSoldField={ this.setAssignNetUnitsSoldField }
          skipNetUnitsSold={ this.skipNetUnitsSold }
          columnNames={ this.state.fileData[headerRow] }
          headerRow={ headerRow }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 9:
        return <SalesMappingStep
          key={`listPriceColumn${id}`}
          panelTitle={"List Price"}
          description={"Which column contains the List Price? Click the Skip button if that does not apply to this file."}
          columnName={"listPriceColumn"}
          mappingColumn={String(this.state.listPriceColumn)}
          disableNext={this.nextDisabled(this.state.listPriceColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 10:
        return <SalesMappingStep
          key={`netAmountColumn${id}`}
          panelTitle={"Net Amount"}
          description={"Which column contains the Net Amount? Click the Skip button if that does not apply to this file."}
          columnName={"netAmountColumn"}
          mappingColumn={String(this.state.netAmountColumn)}
          disableNext={this.nextDisabled(this.state.netAmountColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 11:
        return <SalesMappingStep
          key={`discountRateColumn${id}`}
          panelTitle={"Discount Rate"}
          description={"Which column contains the discount rate? Click the Skip button if that does not apply to this file."}
          columnName={"discountRateColumn"}
          mappingColumn={String(this.state.discountRateColumn)}
          disableNext={this.nextDisabled(this.state.discountRateColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 12:
        return <SalesMappingStep
          key={`discountColumn${id}`}
          panelTitle={"Discount Amount"}
          description={"Which column contains the Dollar/Euro/etc. amount of the discount?"}
          columnName={"discountColumn"}
          mappingColumn={String(this.state.discountColumn)}
          disableNext={this.nextDisabled(this.state.discountColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 13:
        return <SalesMappingStep13
          lookupProcessingEnabled={this.props.lookupProcessingEnabled}
          highlightColumn={this.state.highlightColumn}
          assignNamePage={ this.assignNamePage }
          revenueTypeColumn={this.state.revenueTypeColumn}
          setHighlightColumn={this.setHighlightColumn}
          lookupColumns={this.state.lookupColumns}
          revenueTypeField={this.state.revenueTypeField}
          assignRevenueTypeColumn={ this.state.assignRevenueTypeColumn }
          assignRevenueTypeColumnUsingLookupTable={ this.state.assignRevenueTypeColumnUsingLookupTable}
          assignRevenueTypeField={ this.state.assignRevenueTypeField }
          setRevenueTypeColumn={ this.setRevenueTypeColumn }
          setLookupColumns={ this.setLookupColumns }
          setRevenueTypeField={ this.setRevenueTypeField }
          setAssignRevenueTypeColumn={ this.setAssignRevenueTypeColumn }
          setAssignRevenueTypeColumnUsingLookupTable={ this.setAssignRevenueTypeColumnUsingLookupTable }
          setAssignRevenueTypeField={ this.setAssignRevenueTypeField }
          skipRevenueType={ this.skipRevenueType }
          columnNames={ this.state.fileData[headerRow] }
          headerRow={ headerRow }
          revenueTypes={ this.state.revenueTypes }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 14:
        return <SalesMappingStep
          key={`mfgCostColumn${id}`}
          panelTitle={"Mfg Cost"}
          description={"Which column contains the mfg cost? This field is optional. Click the Skip button if that does not apply to this file."}
          columnName={"mfgCostColumn"}
          mappingColumn={String(this.state.mfgCostColumn)}
          disableNext={this.nextDisabled(this.state.mfgCostColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 15:
        return <SalesMappingStep
            key={`taxColumn${id}`}
            panelTitle={"Tax"}
            description={"Which column contains Tax Info?"}
            columnName={"taxColumn"}
            mappingColumn={String(this.state.taxColumn)}
            disableNext={this.nextDisabled(this.state.taxColumn)}
            addNoneSelection={true}
            setColumn={this.setColumn}
            setHighlightColumn={this.setHighlightColumn}
            columnNames={ this.state.fileData[headerRow] }
            editingMapping={ this.state.editingMapping }
            assignNamePage={ this.assignNamePage }
            nextPage={ this.nextPage }
            previousPage={ this.previousPage } />;
      case 16:
        return <SalesMappingStep
            key={`shippingColumn${id}`}
            panelTitle={"Shipping"}
            description={"Which column contains Shipping Info?"}
            columnName={"shippingColumn"}
            mappingColumn={String(this.state.shippingColumn)}
            disableNext={this.nextDisabled(this.state.shippingColumn)}
            addNoneSelection={true}
            setColumn={this.setColumn}
            setHighlightColumn={this.setHighlightColumn}
            columnNames={ this.state.fileData[headerRow] }
            editingMapping={ this.state.editingMapping }
            assignNamePage={ this.assignNamePage }
            nextPage={ this.nextPage }
            previousPage={ this.previousPage } />;
      case 17:
        return <SalesMappingStep11
          columnNames={ this.state.fileData[headerRow] }
          highlightColumn={this.state.highlightColumn}
          currencyConversion={this.state.currencyConversion}
          conversionRate={this.state.conversionRate}
          currencyCodeColumn={this.state.currencyCodeColumn}
          targetCurrencyCode={this.state.targetCurrencyCode}
          currencyInFile={this.state.currencyInFile}
          setCurrencyInFile={this.setCurrencyInFile}
          setColumn={ this.setColumn }
          setHighlightColumn={this.setHighlightColumn}
          setCurrencyConversion={ this.setCurrencyConversion }
          setTargetCurrencyCode={ this.setTargetCurrencyCode }
          setConversionRate={ this.setConversionRate }
          setSpecifyCurrencyDate={ this.setSpecifyCurrencyDate }
          specifyCurrencyDate={ this.state.specifyCurrencyDate }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 18:
        return <SalesMappingStep12
          highlightColumn={this.state.highlightColumn}
          setDeductionType={this.setDeductionType}
          deductionType={this.state.deductionType}
          setSalesDeductionRate={this.setSalesDeductionRate}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          salesDeductionRate={this.state.salesDeductionRate}
          deductionColumn={this.state.deductionColumn}
          negativeDeductionSign={this.state.negativeDeductionSign}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 19:
        return <SalesMappingStep
          key={`buyerColumn${id}`}
          panelTitle={"Buyer"}
          description={"Which column contains the buyer? Click the Skip button if that does not apply to this file."}
          columnName={"buyerColumn"}
          mappingColumn={String(this.state.buyerColumn)}
          disableNext={this.nextDisabled(this.state.buyerColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 20:
        return <SalesMappingStep
          key={`payoutPlanColumn${id}`}
          panelTitle={"Payout Plan"}
          description={"Which column contains the payout plan? This is used to determine pages read. Click the Skip button if that does not apply to this file."}
          columnName={"payoutPlanColumn"}
          mappingColumn={String(this.state.payoutPlanColumn)}
          disableNext={this.nextDisabled(this.state.payoutPlanColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 21:
        return <SalesMappingStep
          key={`territoryColumn${id}`}
          panelTitle={"Territory"}
          description={"Does this file specify the territory in which the product was sold?  If so, please specify which column contains the country or country code.  Otherwise, click the Skip button."}
          columnName={"territoryColumn"}
          mappingColumn={String(this.state.territoryColumn)}
          disableNext={this.nextDisabled(this.state.territoryColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 22:
        return <SalesMappingStepAccountName
          assignNamePage={ this.assignNamePage }
          accountNameColumn={this.state.accountNameColumn}
          accountNameField={this.state.accountNameField}
          assignAccountNameColumn={ this.state.assignAccountNameColumn }
          assignAccountNameField={ this.state.assignAccountNameField }
          setAccountNameColumn={ this.setAccountNameColumn }
          setAccountNameField={ this.setAccountNameField }
          setAssignAccountNameColumn={ this.setAssignAccountNameColumn }
          setAssignAccountNameField={ this.setAssignAccountNameField }
          setHighlightColumn={this.setHighlightColumn}
          skipAccountName={ this.skipAccountName }
          columnNames={ this.state.fileData[headerRow] }
          headerRow={ headerRow }
          accountNames={ this.state.accountNames }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 23:
        return <SalesMappingStep
          key={`invoiceNumberColumn${id}`}
          panelTitle={"Invoice Number"}
          description={"Some files contain an invoice number, representing a specific invoice or order associated with a given sale. Otherwise, click the Skip button."}
          columnName={"invoiceNumberColumn"}
          mappingColumn={String(this.state.invoiceNumberColumn)}
          disableNext={this.nextDisabled(this.state.invoiceNumberColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 24:
        return <SalesMappingStep
          key={`lineNumberColumn${id}`}
          panelTitle={"Line Number"}
          description={"Some files contain a number, representing a specific line number associated with a given sale. Otherwise, click the Skip button."}
          columnName={"lineNumberColumn"}
          mappingColumn={String(this.state.lineNumberColumn)}
          disableNext={this.nextDisabled(this.state.lineNumberColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 25:
        return <SalesMappingStep
          key={`formatColumn${id}`}
          panelTitle={"Format"}
          description={"Which column contains the format? Click the Skip button if that does not apply to this file."}
          columnName={"formatColumn"}
          mappingColumn={String(this.state.formatColumn)}
          disableNext={this.nextDisabled(this.state.formatColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 26:
        return <SalesMappingStep
          key={`authorNameColumn${id}`}
          panelTitle={"Author Name"}
          description={"Which column contains the Author Name? Click the Skip button if that does not apply to this file."}
          columnName={"authorNameColumn"}
          mappingColumn={String(this.state.authorNameColumn)}
          disableNext={this.nextDisabled(this.state.authorNameColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 27:
        return <SalesMappingStep
          key={`projectNameColumn${id}`}
          panelTitle={"Title"}
          description={"Which column contains the Title? Click the Skip button if that does not apply to this file."}
          columnName={"projectNameColumn"}
          mappingColumn={String(this.state.projectNameColumn)}
          disableNext={this.nextDisabled(this.state.projectNameColumn)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 28:
        return <SalesMappingStep
          key={`miscAlpha1Column${id}`}
          panelTitle={"Misc Alpha 1"}
          description={"This field can be used to store any text data that is present in the file. If you do not need to store any additional data, click the Skip button."}
          columnName={"miscAlpha1Column"}
          mappingColumn={String(this.state.miscAlpha1Column)}
          disableNext={this.nextDisabled(this.state.miscAlpha1Column)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 29:
        return <SalesMappingStep
          key={`miscAlpha2Column${id}`}
          panelTitle={"Misc Alpha 2"}
          description={"This field can be used to store any text data that is present in the file. If you do not need to store any additional data, click the Skip button."}
          columnName={"miscAlpha2Column"}
          mappingColumn={String(this.state.miscAlpha2Column)}
          disableNext={this.nextDisabled(this.state.miscAlpha2Column)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 30:
        return <SalesMappingStep
          key={`miscAlpha3Column${id}`}
          panelTitle={"Misc Alpha 3"}
          description={"This field can be used to store any text data that is present in the file. If you do not need to store any additional data, click the Skip button."}
          columnName={"miscAlpha3Column"}
          mappingColumn={String(this.state.miscAlpha3Column)}
          disableNext={this.nextDisabled(this.state.miscAlpha3Column)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 31:
        return <SalesMappingStep
          key={`miscNumber1Column${id}`}
          panelTitle={"Misc Number 1"}
          description={"This field can be used to store any number data that is present in the file. If you do not need to store any additional data, click the Skip button."}
          columnName={"miscNumber1Column"}
          mappingColumn={String(this.state.miscNumber1Column)}
          disableNext={this.nextDisabled(this.state.miscNumber1Column)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case 32:
        return <SalesMappingStep
          key={`miscNumber2Column${id}`}
          panelTitle={"Misc Number 2"}
          description={"This field can be used to store any number data that is present in the file. If you do not need to store any additional data, click the Skip button."}
          columnName={"miscNumber2Column"}
          mappingColumn={String(this.state.miscNumber2Column)}
          disableNext={this.nextDisabled(this.state.miscNumber2Column)}
          addNoneSelection={true}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case SalesMappingView.SKIP_ROW_STEP:
        return <SalesMappingSkipRow
          highlightColumn={this.state.highlightColumn}
          setColumn={this.setColumn}
          setHighlightColumn={this.setHighlightColumn}
          useSkipRowProcessor={this.state.useSkipRowProcessor}
          setUseSkipRowProcessor={this.setUseSkipRowProcessor}
          skipMatchColumn={this.state.skipMatchColumn}
          setSkipMatchColumn={this.setSkipMatchColumn}
          skipMatchExpression={this.state.skipMatchExpression}
          setSkipMatchExpression={this.setSkipMatchExpression}
          columnNames={ this.state.fileData[headerRow] }
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.assignNamePage }
          previousPage={ this.previousPage } />;
      case SalesMappingView.ASSIGN_NAME_STEP:
        return <SalesMappingStepAssignName
          highlightColumn={this.state.highlightColumn}
          setHighlightColumn={this.setHighlightColumn}
          notes={this.state.notes}
          setNotes={this.setNotes}
          name={this.state.name}
          setName={this.setName}
          editingMapping={ this.state.editingMapping }
          assignNamePage={ this.assignNamePage }
          nextPage={ this.nextPage }
          previousPage={ this.previousPage } />;
      case SalesMappingView.ASSIGN_NAME_STEP + 1:
        return <SalesMappingSummary
          {...this.state}
          skipRowProcessingEnabled={this.props.skipRowProcessingEnabled}
          lookupProcessingEnabled={this.props.lookupProcessingEnabled}
          revenueTypes={this.props.revenueTypes}
          previousPage={ this.assignNamePage }
          createMapping={ this.createMapping }
          updateMapping={ this.updateMapping } />;
    }
  }

  mappingItem(mapping) {
    const activeMapping = this.state.activeMapping || {};
    const itemAttributes = (activeMapping.uuid === mapping.uuid) ?
      { key: mapping.uuid,
        active: true } :
      { key: mapping.uuid,
        href: '#', onClick: this.activateMappingHandler(mapping) };

    if (this.state.showArchived || !mapping.archived ){
      return (<ListGroupItem {...itemAttributes}>
        {mapping.name}
      </ListGroupItem>);
    }
  }

  activateMappingHandler(mapping){
    return event => {
      event.preventDefault();
      this.setState({
        activeMapping: mapping
      });
    };
  }

  findMapping(targetUUID) {
    return this.state.mappings.find((mapping) => targetUUID === mapping.uuid)
  }

  deleteMapping(){
    $.ajax({
      url: "/mappings/" + this.state.activeMapping.uuid,
      type: "DELETE",
      data: {},
      statusCode: {
        200: (response) => {
          this.setState({
            alert: "Template was deleted successfully.",
            alertVisible: true,
            alertSuccessful: true,
            activeMapping: null,
          });
          this.requeryMappings();
        },
        500: () => {
          this.setState({
            alert: "Template was not deleted successfully.",
            alertVisible: true,
            alertSuccessful: false,
          });
        }
      }
    })
  }

  displayTemplate(){
    return(
      <div>
        <SalesMappingDisplay
          skipRowProcessingEnabled={this.props.skipRowProcessingEnabled}
          lookupProcessingEnabled={this.props.lookupProcessingEnabled}
          mapping={ this.state.activeMapping }
          revenueTypes={ this.state.revenueTypes }
          markAsArchived={ this.markAsArchived }
          deleteMapping={ this.deleteMapping }
          editMapping={ this.editMapping }
        />
      </div>
    )
  }

  markAsArchived(){
    $.ajax({
      url: `/mappings/${this.state.activeMapping.uuid}/archive`,
      type: "POST",
      data: {},
      statusCode: {
        200: (response) => {
          this.setState({
            alert: "Template was archived successfully.",
            alertVisible: true,
            alertSuccessful: true,
          });
          this.requeryMappings();
        },
        500: () => {
          this.setState({
            alert: "Template was not archived successfully.",
            alertVisible: true,
            alertSuccessful: false,
          });
        },
        503: () => {
          this.setState({
            alert: "Template is taking a longer time to update.",
            alertVisible: true,
            alertSuccessful: true,
          });
        }
      }
    });
  }

  toggleArchived(){
    this.setState({
      showArchived: !this.state.showArchived
    })
  }

  render() {
    const {mappings} = this.state;

    return (
      <div>
        {this.state.alertVisible ? this.alertWindow() : null}
        <Row>
          <Col md={12}>
            {this.renderStep()}
            {this.state.alertVisible && this.state.page === SalesMappingView.ASSIGN_NAME_STEP ? this.alertWindow() : null}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {this.state.page > 1 && this.state.fileUploaded &&
              <SalesFileDisplay
                fileData={this.showWholeFile() ? this.state.fileData : this.state.filePreviewData}
                highlightColumn={this.state.highlightColumn}
                highlightRow={this.state.highlightRow}
            />}
          </Col>
        </Row>
        {this.state.page === 0 &&
          <Row>
            <Col md={6}>
              <Panel header={<div>Existing Templates</div>}>
                <ListGroup key="mapping-list" className="util-scrollable-list">
                  {mappings.map(this.mappingItem)}
                </ListGroup>
                <Button key='toggleArchived' bsSize="small" onClick={this.toggleArchived}>{this.state.showArchived ? "Hide Archived" : "Show Archived"}</Button>
              </Panel>
            </Col>
            <Col md={6}>
                {this.state.activeMapping === null ? <Panel header={<div>Template Info</div>}></Panel> : this.displayTemplate()}
            </Col>
          </Row>}
      </div>
    )
  }
}

export default SalesMappingView
